import Base = require("Everlaw/Base");
import { ColorTokens, EverColor } from "design-system";
import Cmp = require("Everlaw/Core/Cmp");
import * as Is from "Everlaw/Core/Is";
import Multiplex = require("Everlaw/Multiplex");
import Rest = require("Everlaw/Rest");
import ProductionJob = require("Everlaw/Production/ProductionJob");
import User = require("Everlaw/User");
import UserObject = require("Everlaw/UserObject");
import { ProductionErrorData } from "Everlaw/ProductionErrorData";
import * as Project from "Everlaw/Project";
import { SftpDialog } from "Everlaw/SftpDialog";

import { Color } from "Everlaw/ColorUtil";
import { checkForMalware } from "Everlaw/MalwareWarningDialog";

class Production extends UserObject {
    get className() {
        return "Production";
    }
    override id: number;
    jobId: number;
    projectId: number;
    name: string;
    description: string;
    userId: User.Id;
    batesPrefix: string;
    batesRange: string;
    produceLoadFiles: boolean;
    created: number;
    state: Production.State;
    numDocs: number;
    pageCount: number;
    startTaskProgress: number;
    queuedCount: number;
    processedCount: number;
    packagedCount: number;
    erroredDocCount: number;
    ocrErrorCount: number;
    producedSize: number;
    zipSize: number;
    privilegedCount: number;
    redactedCount: number;
    endorsedCount: number;
    placeholderCount: number;
    deletedCount: number;
    protocolId: number;
    productionErrorData: ProductionErrorData;
    configStatus: Production.ConfigStatus;
    /**
     *  Keeps track of the current number of documents being modified by the production job.
     *  This lets the progress bar show the correct count.
     */
    currentNumDocs: number;
    hasPrivLog: boolean;
    zipfile: String;
    removedDocsFile: string;
    removedWarnings: string[];
    keptWarnings: string[];
    constructor(params: any) {
        super(params);
        this._mixin(params);
    }
    override _mixin(params: any) {
        Object.assign(this, params);
        if (params.state) {
            this.state = Production.State.byString(params.state);
        }
        if (params.productionErrorData) {
            this.productionErrorData = JSON.parse(params.productionErrorData);
        }
        //This value was getting filtered out between the backend
        //and the frontend when it was null. Additionally, mixing in a null value
        //doesn't do anything, so we have to explicitly set it here.
        if (!params.zipfile) {
            this.zipfile = null;
        }
        this.configStatus = (<never>Production.ConfigStatus)[params.configStatus];
    }
    override defaultLastActivity() {
        return this.created;
    }
    getSize() {
        return this.producedSize;
    }
    getMessage() {
        return this.description;
    }
    populateZipSize() {
        Rest.get("production/getZipSize.rest", { id: this.id }).then(
            (size) => {
                this.zipSize = size;
                Base.publish(this);
            },
            () => {},
        );
    }
    setMessage(message: string) {
        Rest.post("production/updateDescription.rest", {
            id: this.id,
            description: message,
        });
    }
    rename(name: string): Promise<string> {
        return Rest.post("production/renameProduction.rest", {
            id: this.id,
            name,
        }).then((data) => {
            this._mixin(data);
            Base.publish(this);
            return this.name;
        });
    }
    override display() {
        return this.name;
    }
    /**
     * Appears in Processing.Dataset, Upload.Homepage, and Production. Used to display a document
     * set object with its type in search, results, review, and datavis.
     */
    displayWithType() {
        return "Production: " + this.display();
    }
    cancel() {
        Rest.post("production/cancel.rest", { productionId: this.id }).then(() =>
            Base.publish(this),
        );
    }

    /**
     * Force a production in progress to end. Error any documents that have not finished processing.
     */
    static terminate(projectId: Project.Id, productionId: Production.Id): Promise<void> {
        return Rest.post(`/${projectId}/production/terminate.rest`, {
            projectId: projectId,
            productionId: productionId,
        });
    }
    canDownload(): boolean {
        return this.state.downloadable && this.configStatus === Production.ConfigStatus.CURRENT;
    }
    canPackageWithProcessingError(): boolean {
        return (
            this.state === Production.State.PROCESSING_ERRORS
            && this.configStatus === Production.ConfigStatus.CURRENT
        );
    }
    /**
     * Submits a task to delete this production and its documents.  When a production
     * is finally deleted, a notification is sent on the production notification channel.
     */
    startDeleteTask() {
        // DeletionWarning imports Document imports Production, this avoids circular dependency
        import("Everlaw/DeletionAndSuspensionWarning").then((DeletionWarning) => {
            let warning = new DeletionWarning.ProductionDocumentDeletionDialog();
            warning.show(this.name, this.currentNumDocs, this.id);
        });
    }
    getUserId() {
        return this.userId;
    }
    getCreated() {
        return this.created;
    }
    override compare(other: Base.Object): number {
        if (!(other instanceof Production)) {
            return super.compare(other);
        }
        // Sort by created, breaking ties by id
        return Cmp.num(other.created, this.created) || Cmp.num(this.id, other.id);
    }
    getColor() {
        return Production.COLOR;
    }
    createSFTPDialog() {
        let eql;
        import("Everlaw/Property").then((Property) => {
            eql = new Property.ProducedFrom(Base.get(Production, this.id), "produced").toString();
            checkForMalware({
                eql,
                onInclude: () => {
                    new SftpDialog({
                        gaName: this.className,
                        usernameUrl: "production/getSftpUsername.rest",
                        credentialsUrl: "production/setSftpCredentials.rest",
                        content: { id: this.id },
                    });
                },
            });
        });
    }
}

module Production {
    export const COLOR = Color.fromEverColor(ColorTokens.OBJECT_PRODUCTION);

    /**
     * Subscribes to the current project's production notifications.
     */
    export function subscribeToCurrentProject(onUpdate: () => void) {
        subscribe("production/subscribe.rest", onUpdate);
    }

    /**
     * Get updates about all productions, cleansed of client data.
     */
    export function subscribeInfo(): void {
        Multiplex.subscribe({
            url: "/productionInfo.rest",
            success: (sub: Multiplex.Subscription, data: ProductionInfo[]) => {
                data && Base.set(ProductionInfo, data);
                sub.begin((resp: { data: ProductionInfo[] }) => {
                    Base.set(ProductionInfo, resp.data);
                });
            },
        });
    }

    function subscribe(url: string, onUpdate?: () => void) {
        Multiplex.subscribe({
            url: url,
            success: (sub: Multiplex.Subscription, data: any[]) => {
                data && Base.set(Production, data);
                sub.begin((resp: { data: any; type: string }) => {
                    if (resp.type === "DELETE") {
                        var prod = Base.get(Production, resp.data);
                        if (prod) {
                            Base.remove(prod);
                        }
                    } else if (resp.type === "JOB") {
                        Base.set(ProductionJob, resp.data);
                    } else {
                        Base.set(Production, resp.data);
                    }
                    onUpdate && onUpdate();
                });
            },
        });
    }

    export declare enum Id {}

    export class State extends Base.Object {
        get className() {
            return "ProductionState";
        }
        active: boolean;
        error: boolean;
        running: boolean;
        modifiable: boolean;
        downloadable: boolean;
        constructor(
            id: string,
            public description: string,
            params: {
                active: boolean;
                error: boolean;
                running: boolean;
                modifiable: boolean;
                downloadable: boolean;
            },
        ) {
            super({ id });
            Object.assign(this, params);
        }
        getTotal(prod: Production | ProductionInfo): number {
            return prod.currentNumDocs;
        }
        getProgress(prod: Production | ProductionInfo): number {
            return 0;
        }
    }

    export module State {
        export function byString(str: string) {
            return Base.get(State, str);
        }

        export const INITIAL = new State("INITIAL", "CREATED", {
            active: false,
            error: false,
            running: false,
            modifiable: false,
            downloadable: false,
        });
        export const CONFIGURED = new State("CONFIGURED", "CONFIGURED", {
            active: false,
            error: false,
            running: false,
            modifiable: false,
            downloadable: false,
        });
        export const CREATINGDOCS = new State("CREATINGDOCS", "CREATING DOCS", {
            active: true,
            error: false,
            running: true,
            modifiable: false,
            downloadable: false,
        });
        CREATINGDOCS.getProgress = (p) => p.startTaskProgress;
        CREATINGDOCS.getTotal = (p) => p.currentNumDocs;
        export const PROCESSING = new State("PROCESSING", "PRODUCING", {
            active: true,
            error: false,
            running: true,
            modifiable: false,
            downloadable: false,
        });
        PROCESSING.getProgress = (p) => p.processedCount;
        export const TERMINATING = new State("TERMINATING", "TERMINATING", {
            active: true,
            error: false,
            running: true,
            modifiable: false,
            downloadable: false,
        });
        TERMINATING.getProgress = (p) => p.erroredDocCount;
        TERMINATING.getTotal = (p) => p.numDocs - p.processedCount;
        export const PROCESSING_ERRORS = new State("PROCESSING_ERRORS", "PROCESSING ERRORS", {
            active: false,
            error: true,
            running: false,
            modifiable: true,
            downloadable: false,
        });
        export const PACKAGED_WITH_PROCESSING_ERRORS = new State(
            "PACKAGED_WITH_PROCESSING_ERRORS",
            "PACKAGED WITH PROCESSING ERRORS",
            {
                active: false,
                error: true,
                running: false,
                modifiable: true,
                downloadable: true,
            },
        );
        export const PACKAGING = new State("PACKAGING", "PACKAGING", {
            active: true,
            error: false,
            running: true,
            modifiable: false,
            downloadable: false,
        });
        PACKAGING.getProgress = (p) => p.packagedCount;
        export const PACKAGING_ERRORS = new State("PACKAGING_ERRORS", "PACKAGING ERRORS", {
            active: false,
            error: true,
            running: false,
            modifiable: true,
            downloadable: false,
        });
        export const REMOVED_DOCS = new State("REMOVED_DOCS", "CREATING REMOVED DOCS CSV", {
            active: true,
            error: false,
            running: true,
            modifiable: false,
            downloadable: false,
        });
        export const FINISHED = new State("FINISHED", "FINISHED", {
            active: false,
            error: false,
            running: false,
            modifiable: true,
            downloadable: true,
        });
        export const ABORTED = new State("ABORTED", "ABORTED", {
            active: false,
            error: true,
            running: false,
            modifiable: false,
            downloadable: false,
        });
        ABORTED.getTotal = (p) => p.numDocs;
        export const CANCELED = new State("CANCELED", "CANCELED", {
            active: false,
            error: true,
            running: false,
            modifiable: false,
            downloadable: false,
        });
        export const DELETING = new State("DELETING", "DELETING", {
            active: true,
            error: false,
            running: true,
            modifiable: false,
            downloadable: false,
        });
        export const FULL_PACKAGING = new State("FULL_PACKAGING", "PACKAGING", {
            active: true,
            error: false,
            running: true,
            modifiable: false,
            downloadable: false,
        });
        FULL_PACKAGING.getProgress = (p) => p.packagedCount;
        DELETING.getTotal = (p) => p.numDocs;
        DELETING.getProgress = (p) => p.deletedCount;

        export const all = [
            INITIAL,
            CONFIGURED,
            CREATINGDOCS,
            PROCESSING,
            TERMINATING,
            PROCESSING_ERRORS,
            PACKAGED_WITH_PROCESSING_ERRORS,
            PACKAGING,
            PACKAGING_ERRORS,
            REMOVED_DOCS,
            FINISHED,
            ABORTED,
            CANCELED,
            DELETING,
            FULL_PACKAGING,
        ];
        all.forEach(Base.add);
    }

    /**
     * Returns all of the productions name currently in use.
     */
    export function usedNames(me?: String) {
        return Base.get(Production)
            .filter((p) => p.name !== me)
            .map((p) => p.name);
    }

    export class Flag extends Base.Object implements Base.Colored {
        get className() {
            return "ProductionFlag";
        }
        constructor(
            id: string,
            public name: string,
            public bitMask: number,
        ) {
            super({ id });
        }
        getColor() {
            // todo: figure out a color for productions
            var color = Color.fromEverColor(EverColor.SAKURA);
            return color;
        }
        override display() {
            return this.name;
        }
    }

    /*
     * This set mirrors those found in ProductionFlag.java.
     */
    export const flags = {
        IsEndorsed: new Flag("IsEndorsed", "Endorsed By Code", 1 << 16),
        IsPlaceholder: new Flag("IsPlaceholder", "Image Placeholder", 1 << 17),
        IsPrivileged: new Flag("IsPrivileged", "Withheld Document", 1 << 18),
        IsRedacted: new Flag("IsRedacted", "Redacted Document", 1 << 19),
        IsError: new Flag("IsError", "Error Document", 1 << 20),
        NoImages: new Flag("NoImages", "No Source Images", 1 << 21),
        OCRError: new Flag("OCRError", "OCR Error", 1 << 22),
        HasOCR: new Flag("HasOCR", "Has OCRed text", 1 << 23),
        ColorDetected: new Flag("ColorDetected", "Color Detected", 1 << 24),
    };

    for (const v in flags) {
        Base.add(flags[v]);
    }

    export enum ConfigStatus {
        CURRENT,
        REPACKAGE,
        MODIFY,
    }

    /**
     * Represents a sanitized version of a {@link Production} with some added information.
     * See Production.java.getSafeJsonAttributes
     */
    export class ProductionInfo extends Base.Object {
        get className(): string {
            return "ProductionInfo";
        }
        override id: number;
        projectId: number;
        userId: User.Id;
        state: Production.State;
        numDocs: number;
        startTaskProgress: number;
        processedCount: number;
        packagedCount: number;
        erroredDocCount: number;
        deletedCount: number;
        currentNumDocs: number;
        jobId: number;

        constructor(params: ProductionInfo) {
            super(params);
            this._mixin(params);
        }

        override _mixin(params: ProductionInfo): void {
            if (Is.string(params.state)) {
                this.state = State.byString(params.state);
                delete params.state;
            }
            Object.assign(this, params);
        }
    }
}

export = Production;
